import { theme } from "../theme";
import { internetProducts, tvProducts, phoneProducts, extraProducts } from "./products";


const expandItemWith = (internet: string, tv: string, phone: string, extra: string) => (item: any) => {
  const internetProduct = internetProducts.find((internetProduct: any) => internetProduct.id === internet);
  const tvProduct = tvProducts.find((tvProduct: any) => tvProduct.id === tv);
  const phoneProduct = phoneProducts.find((phoneProduct: any) => phoneProduct.id === phone);
  const extraProduct = extraProducts.find((extraProduct: any) => extraProduct.id === extra);
  return {
    ...item,
    internetSpeed: internetProduct?.downloadSpeed,
    internetItems: internetProduct?.items,
    tvKabelItems: tvProduct?.cableItems,
    tvDigitalItems: tvProduct?.digitalItems,
    phoneItems: phoneProduct?.items,
    extraItems: extraProduct?.items,
    products: {
      internet: internet,
      tv: tv,
      phone: phone,
      extra: extra,
    }
  }
}

const json = [
  expandItemWith('internet-start', 'tv-start', 'phone-vast')({
    color: theme.colors.product_colors.start,
    productTitle: 'Start Xtra',
    maximumDiscount: 36000,
    maximumDiscountInfoChildren: '<div>Ook gratis extra zenders bechikbaar<br>Bel ons voor het beste advies!</div>',
    priceDiscountedLine1: "Eerste 12 maanden",
    priceDiscountedLine2: "€ 28,-",
    priceTitle: "Daarna € 50,50 p/mnd"
  }),
  expandItemWith('internet-complete', 'tv-start', 'phone-vast')({
    color: theme.colors.product_colors.max,
    productTitle: 'Complete Xtra',
    maximumDiscount: 49500,
    maximumDiscountInfoChildren: '<div>Ook gratis extra zenders bechikbaar<br>Bel ons voor het beste advies!</div>',
    priceDiscountedLine1: "Eerste 12 maanden",
    priceDiscountedLine2: "€ 29,25",
    priceTitle: "Daarna € 55,50 p/mnd"
  }),
  expandItemWith('internet-giga', 'tv-complete', 'phone-vast')({
    color: theme.colors.product_colors.giga,
    productTitle: 'Premium Xtra',
    maximumDiscount: 63200,
    maximumDiscountInfoChildren: '<div>Ook gratis extra zenders bechikbaar<br>Bel ons voor het beste advies!</div>',
    priceDiscountedLine1: "Eerste 12 maanden",
    priceDiscountedLine2: "€ 43,50",
    priceTitle: "Daarna € 76,00 p/mnd"
  }),  
];

export {
  json as packages
}
